/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 localStorage 放進 state.
  architectureCache: oltEncrypt.storeDecodeObject(localStorage.getItem('architecture_cache')),
  stepCache: oltEncrypt.storeDecodeText(sessionStorage.getItem('step_cache')),
};

export const actions = {
  addArchitectureCache({ commit }, data) {
    commit('addArchitectureCache', data);
  },
  updateArchitectureCache({ commit }, data){
    commit('updateArchitectureCache', data);
  },
  deleteArchitectureCache({ commit }, data){
    commit('deleteArchitectureCache', data);
  },
  clearArchitectureCache({ commit }) {
    commit('clearArchitectureCache');
  },
  addStepCache({ commit }, data) {
    commit('addStepCache', data);
  },
  updateStepCacheCurrent({ commit }, data){
    commit('updateStepCacheCurrent', data);
  },
  deleteStepCache({ commit }, data){
    commit('deleteStepCache', data);
  },
  clearStepCache({ commit }) {
    commit('clearStepCache');
  },
};

export const mutations = {
  addArchitectureCache(state, data) {
    if(state.architectureCache && state.architectureCache.length > 0){
      state.architectureCache.push(data);
    }else{
      state.architectureCache = [data];
    }
    localStorage.setItem('architecture_cache', oltEncrypt.storeEncodeObject(state.architectureCache));
  },
  updateArchitectureCache(state, data){
    if(state.architectureCache && state.architectureCache.length > 0){
      for (const [rk, rv] of Object.entries(state.architectureCache)) {
        if(rv.key == data.key){
          state.architectureCache[rk] = data;
          break;
        }
      }
      // console.log("updateArchitectureCache data: ",state.architectureCache);
      localStorage.setItem('architecture_cache', oltEncrypt.storeEncodeObject(state.architectureCache));
    }
  },
  deleteArchitectureCache(state, data) {
    if(state.architectureCache && state.architectureCache.length > 0 && data && data.key){
      for (const [rk, rv] of Object.entries(state.architectureCache)) {
        if(rv.key == data.key){
          state.architectureCache.splice(rk, 1);
          break;
        }
      }
    }
    // console.log("deleteArchitectureCache data: ",state.architectureCache);
    localStorage.setItem('architecture_cache', oltEncrypt.storeEncodeObject(state.architectureCache));
  },
  clearArchitectureCache(state) {
    localStorage.removeItem('architecture_cache');
    state.architectureCache = null;
    // console.log("clearArchitectureCache data: ",state.architectureCache);
  },
  addStepCache(state, data) {
    // console.log("addStepCache before data: ", $.extend(true, [], state.stepCache));
    if(state.stepCache && state.stepCache.length > 0){
      for(const [rk,rv] of Object.entries(state.stepCache)){
        if(rv.isCurrent && rv.isCurrent == true){
          state.stepCache[rk].isCurrent = false;
          let isduplicate = false;
          if(rk == (state.stepCache.length - 1)){
            let index = parseInt(rk);
            state.stepCache.splice(index, 1);
            let olddata = {
              key: data.key,
              uuid: data.uuid,
              id: data.id,
              valueKey: data.valueKey,
              value: data.oldValue,
              isCurrent: false,
            }
            state.stepCache.push(olddata);
            let newdata = {
              key: data.key,
              uuid: data.uuid,
              id: data.id,
              valueKey: data.valueKey,
              value: data.newValue,
              isCurrent: true,
            }
            state.stepCache.push(newdata);
          }else{
            let index = parseInt(rk);
            let length = state.stepCache.length - index;
            state.stepCache.splice(index, length);
            let olddata = {
              key: data.key,
              uuid: data.uuid,
              id: data.id,
              valueKey: data.valueKey,
              value: data.oldValue,
              isCurrent: false,
            }
            state.stepCache.push(olddata);
            let newdata = {
              key: data.key,
              uuid: data.uuid,
              id: data.id,
              valueKey: data.valueKey,
              value: data.newValue,
              isCurrent: true,
            }
            state.stepCache.push(newdata);
          }
          break;
        }
      }
    }else{
      state.stepCache = [{
        key: data.key,
        uuid: data.uuid,
        id: data.id,
        valueKey: data.valueKey,
        value: data.oldValue,
        isCurrent: false,
      },{
        key: data.key,
        uuid: data.uuid,
        id: data.id,
        valueKey: data.valueKey,
        value: data.newValue,
        isCurrent: true,
      }];
    }
    sessionStorage.setItem('step_cache', oltEncrypt.storeEncodeObject(state.stepCache));
    // console.log("addStepCache data: ",state.stepCache);
  },
  deleteStepCache(state, data) {
    if(state.stepCache && state.stepCache.length > 0 && data && data.key){
      for (const [rk, rv] of Object.entries(state.stepCache)) {
        if(rv.key == data.key){
          state.stepCache.splice(rk, 1);
          break;
        }
      }
    }
    // console.log("deleteStepCache data: ",state.stepCache);
    sessionStorage.setItem('step_cache', oltEncrypt.storeEncodeObject(state.stepCache));
  },
  updateStepCacheCurrent(state, data) {
    if(state.stepCache && state.stepCache.length > 0 && data && data.index != null && data.index > -1 && data.index < state.stepCache.length){
      for (const [rk, rv] of Object.entries(state.stepCache)) {
        state.stepCache[rk].isCurrent = false;
      }
      state.stepCache[data.index].isCurrent = true;
    }
    // console.log("updateStepCacheCurrent data: ",state.stepCache);
  },
  clearStepCache(state) {
    sessionStorage.removeItem('step_cache');
    state.stepCache = null;
    // console.log("clearStepCache data: ",state.stepCache);
  },
};


export const getters = {
  isArchitectureCache(state) {
    if (state.architectureCache) {
      return true;
    }
    return false;
  },
  isStepCache(state) {
    if (state.stepCache) {
      return true;
    }
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
