/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  userInfo: oltEncrypt.storeDecodeObject(sessionStorage.getItem('admin_userInfo')),
  timer: {},
  adminPermissions : oltEncrypt.storeDecodeObject(sessionStorage.getItem('admin_adminPermissions')),
  // 顯示筆數 Yves add SBDW5-965
  pageSize : oltEncrypt.storeDecodeObject(sessionStorage.getItem('admin_pageSize')),
  epid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_epid')),
  eid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_eid')),
  euEid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_euEid')),
  tid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_tid')),
  fid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_fid')),
  examTopics: oltEncrypt.storeDecodeObject(sessionStorage.getItem('exam_topics')),
  inid: oltEncrypt.storeDecodeObject(sessionStorage.getItem('institution_inid')),
  firsttask: oltEncrypt.storeDecodeObject(sessionStorage.getItem('firsttask')),
  ayid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_ayid')),
  reviewValue : oltEncrypt.storeDecodeObject(sessionStorage.getItem('review_value')),
  backUrl: oltEncrypt.storeDecodeText(sessionStorage.getItem('backUrl')),
};

export const actions = {
  setUserInfo({ commit }, userInfo) {
    //console.log('action url : ' , urlObj);
    commit('setUserInfo', userInfo);
  },
  cleanUserInfo({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanUserInfo');
  },
  setTimerHeaderViewId({ commit }, id) {
    //console.log('action url : ' , urlObj);
    commit('setTimerHeaderViewId', id);
  },
  setAdminPermissions({ commit }, value) {
    //console.log('userInfo setAdminPermissions value : ', value);
    commit('setAdminPermissions', value);
  },
  cleanAdminPermissions({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanAdminPermissions');
  },
  setReviewValue({ commit }, value) {
    //console.log('userInfo setAdminPermissions value : ', value);
    commit('setReviewValue', value);
  },
  cleanReviewValue({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanReviewValue');
  },
  // 顯示筆數 Yves add SBDW5-965
  setPageSize({ commit }, value) {
    //console.log('userInfo setPageSize value : ', value);
    commit('setPageSize', value);
  },
  // 考卷ID
  setEpid({ commit }, id) {
    commit('setEpid', id);
  },
  // 領域ID
  setFid({ commit }, id) {
    commit('setFid', id);
  },
  // 試題ID
  setEid({ commit }, id) {
    commit('setEid', id);
  },
  // 任務ID
  setTid({ commit }, id) {
    commit('setTid', id);
  },
  // 上架區 - 試題ID
  setEuEid({ commit }, id) {
    commit('setEuEid', id);
  },
  // 已勾選題目清單
  setExamTopics({ commit }, topic) {
    commit('setExamTopics', topic);
  },
  cleanExamTopics({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanExamTopics');
  },
  // 機構ID
  setInid({ commit }, id) {
    commit('setInid', id);
  },
  clearInid({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('clearInid');
  },
  // 首次任務
  setFirstTask({ commit }, status) {
    commit('setFirstTask', status);
  },
  clearFirstTask({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('clearFirstTask');
  },
  // 學年度ID
  setAyid({ commit }, id) {
    commit('setAyid', id);
  },
  cleanAyid({ commit }){
    commit('cleanAyid');
  },
  // 返回路徑
  setBackUrl({ commit }, url) {
    commit('setBackUrl', url);
  },
  cleanBackUrl({ commit }){
    commit('cleanBackUrl');
  }
};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  setUserInfo(state, userInfo) {
    sessionStorage.setItem('admin_userInfo', oltEncrypt.storeEncodeObject(userInfo));
    state.userInfo = userInfo;
  },
  cleanUserInfo(state) {
    sessionStorage.removeItem('admin_userInfo');
    state.userInfo = null;
  },
  setTimerHeaderViewId(state, id) {
    state.timer.headerViewId = id;
  },
  setAdminPermissions(state, value) {
    //console.log('userInfo setAdminPermissions mutations value : ', value);
    sessionStorage.setItem('admin_adminPermissions', oltEncrypt.storeEncodeObject(value));
    state.adminPermissions = value;
    //console.log('userInfo setAdminPermissions state2 : ', state);
  },
  cleanAdminPermissions(state) {
    state.adminPermissions = null;
  },
  setReviewValue(state, value) {
    sessionStorage.setItem('review_value', oltEncrypt.storeEncodeObject(value));
    state.reviewValue = value;
  },
  cleanReviewValue(state) {
    state.reviewValue = null;
  },
  // 顯示筆數 Yves add SBDW5-965
  setPageSize(state, value) {
    //console.log('userInfo setPageSize mutations value : ', value);
    sessionStorage.setItem('admin_pageSize', oltEncrypt.storeEncodeObject(value));
    state.pageSize = value;
    //console.log('userInfo setPageSize state2 : ', state);
  },
  // 考卷 ID
  setEpid(state, id) {
    sessionStorage.setItem('admin_epid', oltEncrypt.storeEncodeText(id));
    state.epid = id;
  },
  // 領域 ID
  setFid(state, id) {
    sessionStorage.setItem('admin_fid', oltEncrypt.storeEncodeText(id));
    state.fid = id;
  },
  // 任務 ID
  setEid(state, id) {
    sessionStorage.setItem('admin_eid', oltEncrypt.storeEncodeText(id));
    state.eid = id;
  },
  // 試題 ID
  setTid(state, id) {
    sessionStorage.setItem('admin_tid', oltEncrypt.storeEncodeText(id));
    state.tid = id;
  },
  // 上架區 - 試題 ID
  setEuEid(state, id) {
    sessionStorage.setItem('admin_euEid', oltEncrypt.storeEncodeText(id));
    state.euEid = id;
  },
  // 已勾選題目清單
  setExamTopics(state, topic) {
    sessionStorage.setItem('exam_topics', oltEncrypt.storeEncodeObject(topic));
    state.examTopics = topic;
  },
  cleanExamTopics(state) {
    state.examTopics = null;
  },
  // 已勾選題目清單
  setInid(state, id) {
    sessionStorage.setItem('institution_inid', oltEncrypt.storeEncodeObject(id));
    state.inid = id;
  },
  clearInid(state) {
    state.inid = null;
  },
  // 已勾選題目清單
  setFirstTask(state, status) {
    sessionStorage.setItem('firsttask', oltEncrypt.storeEncodeObject(status));
    state.firsttask = status;
  },
  clearFirstTask(state) {
    state.firsttask = null;
  },
  // 學年度 ID
  setAyid(state, id) {
    sessionStorage.setItem('admin_ayid', oltEncrypt.storeEncodeText(id));
    state.ayid = id;
  },
  cleanAyid(state) {
    sessionStorage.removeItem('admin_ayid');
    state.ayid = null;
  },
  // 返回路徑
  setBackUrl(state, id) {
    sessionStorage.setItem('backUrl', oltEncrypt.storeEncodeText(id));
    state.backUrl = id;
  },
  cleanBackUrl(state) {
    sessionStorage.removeItem('backUrl');
    state.backUrl = null;
  },
};


export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isUserInfo(state) {
    if (state.userInfo) {
      return true;
    }
    return false;
  },
  isAdminPermissions(state) {
    if (state.adminPermissions) {
      return true;
    }
    return false;
  },
  isReviewValue(state) {
    if (state.reviewValue) {
      return true;
    }
    return false;
  },
  isPageSize(state) {
    if (state.pageSize) {
      return true;
    }
    return false;
  },
  isEpid(state) {
    if (state.epid) {
      return true;
    }
    return false;
  },
  isFid(state) {
    if (state.fid) {
      return true;
    }
    return false;
  },
  isEid(state) {
    if (state.eid) {
      return true;
    }
    return false;
  },
  isTid(state) {
    if (state.tid) {
      return true;
    }
    return false;
  },
  isEuEid(state) {
    if (state.euEid) {
      return true;
    }
    return false;
  },
  isExamTopics(state) {
    if (state.examTopics) {
      return true;
    }
    return false;
  },
  isInid(state) {
    if (state.inid) {
      return true;
    }
    return false;
  },
  isFirsttask(state) {
    if (state.firsttask) {
      return true;
    }
    return false;
  },
  isAyid(state) {
    if (state.ayid) {
      return true;
    }
    return false;
  },
  isBackUrl(state) {
    if (state.backUrl) {
      return true;
    }
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
