/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltUserInfo{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUserInfo!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  
  setUserInfo(store,Obj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('setUserInfo',Obj);
    //console.log(store.state);
  }

  cleanUserInfo(store){
    store.dispatch('cleanUserInfo');
    //console.log(store.state);
  }

  getUserInfo(store){
    if(store.getters.isUserInfo){
      return store.state.userInfo.userInfo;
    }
    return false ; 
  }

  setTimerHeaderViewId(store,id){
    store.dispatch('setTimerHeaderViewId',id);
  }

  getTimerHeaderViewId(store){
    return store.state.userInfo.timer.headerViewId;
  }

  
  setAdminPermissions(store, Obj){
    //console.log('oltUserInfo setAdminPermissions Obj : ', Obj);
    store.dispatch('setAdminPermissions', Obj);
    //console.log(store.state);
  }

  cleanAdminPermissions(store){
    store.dispatch('cleanAdminPermissions');
    //console.log(store.state);
  }

  getAdminPermissions(store){
    if(store.getters.isAdminPermissions){
      return store.state.userInfo.adminPermissions;
    }
    return false ; 
  }
  
  setReviewValue(store, Obj){
    store.dispatch('setReviewValue', Obj);
  }

  cleanReviewValue(store){
    store.dispatch('cleanReviewValue');
  }

  getReviewValue(store){
    if(store.getters.isReviewValue){
      return store.state.userInfo.reviewValue;
    }
    return false ; 
  }

  getAdminRoleFunRtByFunsCode(store, code){
    if(store.getters.isAdminPermissions && store.state.userInfo.adminPermissions.rtAllRecord &&
      store.state.userInfo.adminPermissions.rtAllRecord.AdminRoleFunRtJoin && store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns){
      for (const [fk, fv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns)) {
        if(fv.code == code){
          for (const [rk, rv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminRoleFunRtJoin)) {
            if(rv.fid > 0 && rv.fid == fv.fid){
              return rv;
            }
          }
        }
      }
    }
    return false ; 
  }

  getAdminFunsByFunsCode(store, code){
    if(store.getters.isAdminPermissions && store.state.userInfo.adminPermissions.rtAllRecord &&
      store.state.userInfo.adminPermissions.rtAllRecord.AdminRoleFunRtJoin && store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns){
      for (const [fk, fv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns)) {
        if(fv.code == code){
          return fv;
        }
      }
    }
    return false ; 
  }

  getAdminModulesByFunsCode(store, code){
    if(store.getters.isAdminPermissions && store.state.userInfo.adminPermissions.rtAllRecord &&
      store.state.userInfo.adminPermissions.rtAllRecord.AdminRoleFunRtJoin && store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns){
      for (const [fk, fv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns)) {
        if(fv.code == code){
          for (const [rk, rv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminModules)) {
            if(rv.mid > 0 && rv.mid == fv.mid){
              return rv;
            }
          }
        }
      }
    }
    return false ; 
  }

  getAdminFunsByFunsID(store, fid){
    if(store.getters.isAdminPermissions && store.state.userInfo.adminPermissions.rtAllRecord &&
      store.state.userInfo.adminPermissions.rtAllRecord.AdminRoleFunRtJoin && store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns){
      for (const [fk, fv] of Object.entries(store.state.userInfo.adminPermissions.rtAllRecord.AdminFuns)) {
        if(fv.fid == fid){
          return fv;
        }
      }
    }
    return false ; 
  }
  
  /*
  * 設定顯示筆數
  * Yves add SBDW5-965
  */
  setPageSize(store, Obj){
    store.dispatch('setPageSize', Obj);
  }  

  /*
  * 顯示筆數
  * Yves add SBDW5-965
  */
  getPageSize(store){
    if(store.getters.isPageSize){
      return store.state.userInfo.pageSize;
    }
    return 20; 
  }

  /**
   * 考卷 ID
   */
  setEpid(store, id){
    store.dispatch('setEpid', id);
  }
  getEpid(store) {
    let id = '';

    if (store.getters.isEpid) {
      id = store.state.userInfo.epid;
    }
    return id;
  }

  /**
   * 考卷 ID
   */
  setFid(store, id){
    store.dispatch('setFid', id);
  }
  getFid(store) {
    let id = '';
  
    if (store.getters.isFid) {
      id = store.state.userInfo.fid;
    }
    return id;
  }

  /**
   * 試題 ID
   */
  setEid(store, id){
    store.dispatch('setEid', id);
  }
  getEid(store) {
    let id = '';

    if (store.getters.isEid) {
      id = store.state.userInfo.eid;
    }
    return id;
  }

  /**
   * 任務 ID
   */
  setTid(store, id){
    store.dispatch('setTid', id);
  }
  getTid(store) {
    let id = '';

    if (store.getters.isTid) {
      id = store.state.userInfo.tid;
    }
    return id;
  }

  /**
   * 上架區 - 試題 ID
   */
  setEuEid(store, id){
    store.dispatch('setEuEid', id);
  }
  getEuEid(store) {
    let id = '';

    if (store.getters.isEuEid) {
      id = store.state.userInfo.euEid;
    }
    return id;
  }
  
  
  /**
   * 已勾選題目清單
   */
  setExamTopics(store, topic){
    store.dispatch('setExamTopics', topic);
  }
  getExamTopics(store) {
    let topic = [];

    if (store.getters.isExamTopics) {
      topic = store.state.userInfo.examTopics;
    }
    return topic;
  }
  cleanExamTopics(store){
    store.dispatch('cleanExamTopics');
    //console.log(store.state);
  }

  /**
   *  - 機構ID
   */
  setInid(store, id){
    store.dispatch('setInid', id);
  }
  getInid(store) {
    let id = '';

    if (store.getters.isInid) {
      id = store.state.userInfo.inid;
    }
    return id;
  }

  /**
   *  - 首次任務
   */
  setFirstTask(store, status){
    store.dispatch('setFirstTask', status);
  }
  getFirstTask(store) {
    let status = '';

    if (store.getters.isFirsttask) {
      status = store.state.userInfo.firsttask;
    }
    return status;
  }

  /**
   * 學年度ID
   */
  setAyid(store, id){
    store.dispatch('setAyid', id);
  }
  getAyid(store) {
    let id = '';
  
    if (store.getters.isAyid) {
      id = store.state.userInfo.ayid;
    }
    return id;
  }
  cleanAyid(store){
    store.dispatch('cleanAyid');
  }
  
  /**
   * 返回路徑
   */
  setBackUrl(store, url){
    store.dispatch('setBackUrl', url);
  }
  getBackUrl(store) {
    let url = '';
  
    if (store.getters.isBackUrl) {
      url = store.state.userInfo.backUrl;
    }
    return url;
  }
  cleanBackUrl(store){
    store.dispatch('cleanBackUrl');
  }
}